import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/PackshotStill_Jubileum.jpg"
import { navigate } from "gatsby-link"

const jubileum = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Jubileum. " image={image} />
    <div className="result" id="1">
      <h3>Olet nopea ja nokkela.</h3>
      <p>
        Älysi leikkaa ja kehosi liikkuu kuin ketulla konsanaan. Et halua
        pysähtyä ja ajatteletkin terävämmin liikkeessä. Kykenet salamannopeisiin
        ratkaisuihin ja sinulle on luontaista ottaa porukka kuin porukka
        johtoosi. 
        <br />
        <br />
        Kahvisi on Löfbergs Jubileum. Elegantti ja monivivahteinen.
      </p>
      <StaticImage
        src="../assets/PackshotStill_Jubileum.jpg"
        alt="Löfbergs Jubileum"
      />
      <Buttons
        quote="Kahvini on Löfbergs Jubileum. Elegantti ja monivivahteinen."
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default jubileum
